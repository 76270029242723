"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnityContext = void 0;
var event_system_1 = require("./event-system");
/**
 * A Unity Context object can be fed to a Unity component instance to configure
 * the Unity Instance and handle the communication between the two.
 */
var UnityContext = /** @class */ (function (_super) {
    __extends(UnityContext, _super);
    /**
     * Creates a new Unity Context instance which can be fed to a Unity component
     * in order to render a Unity Instance.
     * @param unityConfig The Unity Config
     */
    function UnityContext(unityConfig) {
        var _this = _super.call(this) || this;
        _this.unityConfig = unityConfig;
        _this.unityInstance = null;
        _this.htmlCanvasElement = null;
        return _this;
    }
    /**
     * Sends a message to the UnityInstance to invoke a public method.
     * @public
     * @param {string} gameObjectName the name of the game object in your Unity scene.
     * @param {string} methodName the name of the public method on the game object.
     * @param {string | number | boolean} parameter an optional method parameter.
     */
    UnityContext.prototype.send = function (gameObjectName, methodName, parameter) {
        if (this.unityInstance !== null) {
            if (typeof parameter === "undefined") {
                this.unityInstance.SendMessage(gameObjectName, methodName);
            }
            else {
                this.unityInstance.SendMessage(gameObjectName, methodName, parameter);
            }
        }
    };
    /**
     * Asynchronously ask for the pointer to be locked on current canvas. To track
     * the success or failure of the request, it is necessary to listen for the
     * pointerlockchange and pointerlockerror events at the Document level.
     * @public
     * @see https://developer.mozilla.org/en-US/docs/Web/API/Element/requestPointerLock
     */
    UnityContext.prototype.requestPointerLock = function () {
        if (this.htmlCanvasElement !== null) {
            this.htmlCanvasElement.requestPointerLock();
        }
    };
    /**
     * Takes a screenshot of the canvas and returns a data URL containing image
     * data. The image data is in .png format unless otherwise specified.
     * @public
     * @param dataType The image format of the screenshot
     * @param quality The quality of the jpg or webp screenshot
     * @returns a data URL containing image data of a snapshot of the canvas
     */
    UnityContext.prototype.takeScreenshot = function (dataType, quality) {
        var _a;
        if (this.htmlCanvasElement !== null) {
            if (((_a = this.unityConfig.webglContextAttributes) === null || _a === void 0 ? void 0 : _a.preserveDrawingBuffer) !== true) {
                console.warn("Taking a screenshot requires 'preserveDrawingBuffer'.");
            }
            return this.htmlCanvasElement.toDataURL(dataType, quality);
        }
        return null;
    };
    /**
     * Enables or disabled the Fullscreen mode of the Unity Instance.
     * @public
     * @param {boolean} enabled
     */
    UnityContext.prototype.setFullscreen = function (enabled) {
        if (this.unityInstance !== null) {
            this.unityInstance.SetFullscreen(enabled === true ? 1 : 0);
        }
    };
    /**
     * Quits the Unity Instance and clears it from memory.
     * @public
     * @async
     * @returns A promise that resolves when the Unity Instance is quit.
     */
    UnityContext.prototype.quitUnityInstance = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.unityInstance !== null)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.unityInstance.Quit()];
                    case 1:
                        _a.sent();
                        this.unityInstance = null;
                        this.dispatchEvent("quitted");
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    return UnityContext;
}(event_system_1.EventSystem));
exports.UnityContext = UnityContext;
